<template>
  <div class="container">
    <div class="about__title title">О приложении</div>
    <div class="about__text text">
      В данном разделе мы собрали ответы на самые часто задаваемые вопросы
      посетителей. Если среди наших ответов Вы так и не смогли найти ответ на
      свой вопрос, воспользуйтесь формой "Задать вопрос" и в ближайшее время мы
      обязательно на него ответим.
    </div>
    <div class="typography">
      <h2>
        В нашем ассортименте свыше 30.000 наименований товаров, таких как:
      </h2>
      <ul>
        <li>
          Свежие продукты (сезонные фрукты и овощи, мясо, рыба, колбасные
          изделия, сыры, напитки, чай, кофе, сливки, кондитерские изделия,
          бакалея)
        </li>
        <li>Чистящие и моющие средства</li>
        <li>Посуда и хозяйственные товары</li>
        <li>Канцтовары, картриджи и бумага для офисной техники</li>
        <li>Формирование подарочных наборов для поздравления сотрудников</li>
      </ul>
      <p>
        <span>«Palladi.ru» &#32;</span>- это удобный сервис по подбору товаров и
        оформлению заказов, не покидая рабочее место, экономия Вашего времени,
        высокое качество товара и обслуживания. С каждым корпоративным клиентом
        работает персональный менеджер.
      </p>
    </div>
  </div>
</template>
